<template>
  <div class="section">
    <!-- Big TV screens -->
    <div class="columns">
      <div class="column content">
        <h3>Big TV Screens</h3>
        <ul>
          <li>
            <a
              href="/"
              target="_blank"
              >Screen 1 (first 2 weeks)</a
            >
          </li>
          <li>
            <a
              href="/month2"
              target="_blank"
              >Screen 2 (second 2 weeks)</a
            >
          </li>
        </ul>
      </div>
      <div class="column content">
        These are the pages that show up on the screens; you can pull them up on
        your computer if you want.
      </div>
    </div>

    <!-- Basic Airtable views -->
    <div class="columns">
      <div class="column content">
        <h3>Basic Airtable Views</h3>
        <ul>
          <li>
            <a
              href="https://airtable.com/app8mbqWVu9vW1KHE/tblHIPPdwY0y9EPb0/viwuSSoczOgapxYvQ?blocks=hide"
              target="_blank"
              >Master</a
            >
          </li>
          <li>
            <a
              href="https://airtable.com/app8mbqWVu9vW1KHE/tblHIPPdwY0y9EPb0/viwOtfj3HPfQFUoiT?blocks=hide"
              target="_blank"
              >Cancelled</a
            >
          </li>
          <li>
            <a
              href="https://airtable.com/app8mbqWVu9vW1KHE/tblHIPPdwY0y9EPb0/viw5iacAL5aPURiQY?blocks=hide"
              target="_blank"
              >Postponed</a
            >
          </li>
          <li>
            <a
              href="https://airtable.com/app8mbqWVu9vW1KHE/tblHIPPdwY0y9EPb0/viwxjS1AtLz6GmtWk?blocks=hide"
              target="_blank"
              >Yesterday jobs</a
            >
          </li>
          <li>
            <a
              href="https://airtable.com/app8mbqWVu9vW1KHE/tblHIPPdwY0y9EPb0/viw3m8kLG54otRDJX?blocks=hide"
              target="_blank"
              >Today's jobs</a
            >
          </li>
          <li>
            <a
              href="https://airtable.com/app8mbqWVu9vW1KHE/tblHIPPdwY0y9EPb0/viwYaNv1i0CS8fKFN?blocks=hide"
              target="_blank"
              >Tomorrow's jobs</a
            >
          </li>
          <li>
            <a
              href="https://airtable.com/app8mbqWVu9vW1KHE/tblHIPPdwY0y9EPb0/viwp0ZdY55JBD3HvA?blocks=hide"
              target="_blank"
              >Archive of jobs by month</a
            >
          </li>
        </ul>
      </div>
      <div class="column content">
        <p>
          The master view, plus other views that you may need to check
          occasionally.
        </p>
        <p>These can also be found in the left column of Airtable.</p>
        <p>
          There are some other Airtable views used in specific processes which
          are linked below.
        </p>
      </div>
    </div>

    <!-- Dispatch Printout (Tomorrow's Schedule) -->
    <div class="columns">
      <div class="column content">
        <h3>Dispatch Printout (Tomorrow's Schedule)</h3>
        <ul>
          <li>
            <a
              href="/tomorrow"
              target="_blank"
              >Custom Dispatch Printout</a
            >
          </li>
          <li>
            <a
              href="https://airtable.com/app8mbqWVu9vW1KHE/tblHIPPdwY0y9EPb0/viwYaNv1i0CS8fKFN?blocks=hide"
              target="_blank"
              >Airtable "Tomorrow" View</a
            >
          </li>
        </ul>
      </div>
      <div class="column content">
        <p>
          Data for the jobs can be edited in Airtable, either in the Master view or in the "Tomorrow"
          view.
        </p>
        <p>
          The daily job schedule can be printed from the Custom Dispatch Printout link:
        </p>
        <ol>
          <li>Choose a date</li>
          <li>Drag rows in the table to reorder them if you want</li>
          <li>Under "Color", click the color to put a color swatch on the row</li>
          <li>Click "Print Table" once it's ready</li>
          <li>Make sure the print settings include "print background graphics" so you get the colors.</li>
        </ol>
      </div>
    </div>


    <!-- Pending Jobs -->
    <div class="columns">
      <div class="column content">
        <h3>Pending Jobs</h3>
        <ul>
          <li>
            <a
              href="/sales-picker"
              target="_blank"
              >Sales Picker (Scheduler tool)</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://airtable.com/app8mbqWVu9vW1KHE/tblHIPPdwY0y9EPb0/viwtHp3S6Xuy1EeS7?blocks=hide"
              >Airtable: Upcoming pending jobs</a
            >
          </li>
        </ul>
      </div>
      <div class="column content">
        <p>
          <strong>Sales</strong> people will add jobs using the Sales Picker.
          Usually, jobs are automatically added to the schedule.
        </p>
        <p>
          However, a day is marked "Booked" if it has at least 6 confirmed jobs
          (3 on Saturdays). When a sales person wants to schedule another job on
          a Booked day, it will be marked "Pending" and a small badge will show
          up on the big screens.
        </p>
        <p>
          <strong>Office</strong> can check the "Upcoming Pending" Airtable view
          to see pending jobs. Edit information as needed, then change status to
          "Confirmed" to approve the job.
        </p>
      </div>
    </div>

    <!-- Office: Creating/editing jobs and assigning resources -->
    <div class="columns">
      <div class="column content">
        <h3>Office: Creating/editing jobs and assigning resources</h3>
        <ul>
          <li>
            <a
              href="/picker"
              target="_blank"
              >Picker (scheduler)</a
            >
          </li>
          <li>Or use Master view in Airtable</li>
        </ul>
      </div>
      <div class="column content">
        <p>
          In the office, you can add jobs using the Master sheet in Airtable
          (linked above) or using the Picker.
        </p>
        <ul>
          <li>
            Any "Pending" jobs are listed at top. Click a job to load its
            details in the form below.
          </li>
          <li>
            Or, choose a date ("booked" dates marked red) and click "Get Jobs,
            Trucks, &amp; Crew". You'll see a list of jobs for the date. Click
            one to edit it, or start adding a new job in the form below.
          </li>
          <li>
            You'll see a list of trucks, drivers, and crew which are available
            for the date. If they are gray, it means they're already used on at
            least one job on that date - but you can still schedule them.
          </li>
          <li>
            When done, click "Submit Booking" at bottom and job will be
            scheduled.
          </li>
        </ul>
      </div>
    </div>

    <!-- Hot Sheets, Sales editing jobs, Reviewing changes -->
    <div class="columns">
      <div class="column content">
        <h3>Hot Sheets, Sales editing jobs, Reviewing changes</h3>
        <ul>
          <li>
            <a
              href="/sales-picker"
              target="_blank"
              >Sales Picker (same link as above)</a
            >
          </li>
          <li>
            <a
              href="https://airtable.com/app8mbqWVu9vW1KHE/tblHIPPdwY0y9EPb0/viw0O5eqNMBr4wvSH?blocks=hide"
              target="_blank"
              >Airtable - Review Sales Changes</a
            >
          </li>
          <li>
            <a
              href="https://airtable.com/app8mbqWVu9vW1KHE/tblEoOYHlmbLnr0tp/viwtP3m02DW0iNBTb?blocks=bli0SzdPGXGv4Qzc7"
              target="_blank"
              >Airtable - View/Edit/Print Hot Sheets</a
            >
          </li>
        </ul>
      </div>
      <div class="column content">
        <p>
          <strong>Sales</strong> people can edit jobs using the Sales Picker.
          They choose their initials to see a list of upcoming jobs they have
          scheduled. They can click a job to choose it.
        </p>
        <p>
          When sales edits a job this way, it will be marked "Sales Changes"
          instead of "Confirmed".
        </p>
        <p>
          <strong>Sales</strong> can add Hot Sheet information while editing a
          job. They should click the button at bottom to add a Hot Sheet, then
          the fields will be displayed. <strong>Sales can print Hot Sheets now</strong>
        </p>
        <p>
          <strong>Office</strong> can review "Sales Changes" jobs in Airtable
          using link at left. Re-mark the job as Confirmed when done.
        </p>
        <p>
          <strong>Office</strong> can view/update/print Hot Sheets in Airtable
          using link at left
        </p>
        <ul>
          <li>
            Click the magnifying glass "Go to record..." link to search for a
            job
          </li>
          <li>Click "Expand Layout" to edit Hot Sheet information if needed</li>
          <li>"Print" is at far right</li>
        </ul>
        <p>
          Office can also view/update/print Hot Sheets using the Sales Picker link at left.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dash",
};
</script>

<style scoped lang="scss">
.columns {
  border-bottom: 1px solid #dddddd;
}
</style>
