<template>
  <div class="section">
    <div class="no-print">
      <h4 class="is-size-4">Dispatch Date</h4>
      <o-datepicker v-model="dispatchDate" inline></o-datepicker>
      <br />
      <o-button @click="getJobsForDate()"
        >Get Jobs for this date &rarr;</o-button
      >
      <br /><br /><br />
    </div>

    <div v-if="gotJobs">
      <div v-if="gotJobs && jobs.length === 0">No jobs for this date</div>
      <div v-if="gotJobs && jobs.length > 0">
        <o-button @click="printTable()" class="no-print">Print Table</o-button>
        <p class="no-print">
          <em>Drag and drop rows to reorder if needed</em>
        </p>
        <h3 class="is-size-3" style="text-align: center">{{ jobs[0].D }}</h3>
        <br /><br />
        <o-table
          :data="jobs"
          draggable
          @dragstart="dragstart"
          @drop="drop"
          @dragover="dragover"
          @dragleave="dragleave"
          class="tomorrow-table"
        >
          <o-table-column field="color" label="Color" v-slot="props">
            <o-button @click="updateColor(props.index)" :style="{ 'background-color': colors[props.row.color] }">
              <span class="no-print" style="color: black;">Color {{props.row.color + 1}}</span>
            </o-button>
          </o-table-column>
          <o-table-column field="Job Name" label="Job Name" v-slot="props">
            {{props.row['Job Name']}}
          </o-table-column>
          <o-table-column field="time" label="time" v-slot="props">
            {{props.row['time']}}
          </o-table-column>
          <o-table-column field="JOBS#" label="JOBS#" v-slot="props">
            {{props.row['JOBS#']}}
          </o-table-column>
          <o-table-column field="Driver" label="Driver" v-slot="props">
            {{props.row['Driver']}}
          </o-table-column>
          <o-table-column field="Crew" label="Crew" v-slot="props">
            {{props.row['Crew']}}
          </o-table-column>
          <o-table-column field="Truck" label="Truck" v-slot="props">
            {{props.row['Truck']}}
          </o-table-column>
          <o-table-column field="REG#" label="REG#" v-slot="props">
            {{props.row['REG#']}}
          </o-table-column>
          <o-table-column field="Notes" label="Notes" v-slot="props">
            {{props.row['Notes']}}
          </o-table-column>
          <o-table-column field="Location" label="Location" v-slot="props">
            {{props.row['Location']}}
          </o-table-column>
          <o-table-column field="Sales" label="Sales" v-slot="props">
            {{props.row['Sales']}}
          </o-table-column>
        </o-table>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import axios from "axios";

export default {
  name: "Tomorrow",

  data() {
    return {
      draggingRow: null,
      draggingRowIndex: null,
      dispatchDate: new Date(),
      jobs: [],
      trucks: [],
      drivers: [],
      crew: [],
      gotJobs: false,
      colors: [
        'transparent',
        '#ffd8a8',
        '#b2f2bb',
        '#ffc9c9',
        '#99e9f2'
      ]
    };
  },

  computed: {
    dispatchFormat: function () {
      if (this.dispatchDate != "") {
        return format(this.dispatchDate, "yyyy-MM-dd");
      } else {
        return "";
      }
    },
  },

  methods: {
    async getJobsForDate() {
      this.gotJobs = false;
      let ret = await axios.get(
        "https://nodered.proqsolutions.com/prestige/wAirtableDaily?dispatch=" +
          this.dispatchFormat
      );

      this.trucks = ret.data.trucks;
      this.drivers = ret.data.drivers;
      this.crew = ret.data.crew;

      ret.data.jobs = ret.data.jobs.filter((j) => {
        let f = true;
        if (j.fields.Status === "Off/Out") {
          f = false;
        }
        return f;
      });

      ret.data.jobs = ret.data.jobs.map((j) => {
        // Add trucks, drivers, crew s.t. they can be filled into the fields
        j.fields.TruckNames = [];
        if ("Truck" in j.fields) {
          j.fields.TruckNames = j.fields.Truck.map((t) => {
            const item = this.trucks.find((a) => {
              let ret = false;
              if (a.id === t) {
                ret = true;
              }
              return ret;
            });
            return item.fields.Name;
          });
        }
        j.fields.CrewNames = [];
        if ("Crew" in j.fields) {
          j.fields.CrewNames = j.fields.Crew.map((t) => {
            const item = this.crew.find((a) => {
              let ret = false;
              if (a.id === t) {
                ret = true;
              }
              return ret;
            });
            return item.fields.Name;
          });
        }
        j.fields.DriverNames = [];
        if ("Driver" in j.fields && j.fields.Driver.length > 0) {
          j.fields.DriverNames = j.fields.Driver.map((t) => {
            const item = this.drivers.find((a) => {
              let ret = false;
              if (a.id === t) {
                ret = true;
              }
              return ret;
            });
            // console.log(j.fields['Job Name']);
            // console.log(item);
            if(item) {
              return item.fields.Name;
            } else {
              return '';
            }
          });
        }
        return j;
      });

      this.jobs = ret.data.jobs.map((j) => {
        const job = {
          Crew:
            j.fields.CrewNames.length > 0 ? j.fields.CrewNames.join(", ") : "",
          Driver:
            j.fields.DriverNames.length > 0
              ? j.fields.DriverNames.join(", ")
              : "",
          "Job Name": j.fields["Job Name"] ? j.fields["Job Name"] : "",
          "JOBS#": j.fields["JOBS#"] ? j.fields["JOBS#"] : "",
          Location: j.fields.Location ? j.fields.Location : "",
          Notes: j.fields.Notes ? j.fields.Notes : "",
          "REG#": j.fields["REG#"] ? j.fields["REG#"] : "",
          Sales: j.fields.Sales ? j.fields.Sales : "",
          time: j.fields.time ? j.fields.time : "",
          Truck:
            j.fields.TruckNames.length > 0
              ? j.fields.TruckNames.join(", ")
              : "",
          D: j.fields.D ? j.fields.D : "",
          id: j.id,
          color: 0,
        };
        return job;
      });
      this.gotJobs = true;
    },

    updateColor(rowIndex) {
      this.jobs[rowIndex].color += 1;
      if(this.jobs[rowIndex].color === 5) {
        this.jobs[rowIndex].color = 0;
      }
    },

    printTable() {
      window.print();
    },

    dragstart(payload) {
      this.draggingRow = payload.row;
      this.draggingRowIndex = payload.index;
      payload.event.dataTransfer.effectAllowed = "copy";
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = "copy";
      payload.event.target.closest("tr").classList.add("is-selected");
      payload.event.preventDefault();
    },
    dragleave(payload) {
      payload.event.target.closest("tr").classList.remove("is-selected");
      payload.event.preventDefault();
    },
    drop(payload) {
      payload.event.target.closest("tr").classList.remove("is-selected");
      const droppedOnRowIndex = payload.index;
      if (this.draggingRowIndex !== droppedOnRowIndex) {
        const tempJobs = this.jobs;
        delete this.jobs;
        this.jobs = [];
        tempJobs.splice(
          droppedOnRowIndex,
          0,
          tempJobs.splice(this.draggingRowIndex, 1)[0]
        );
        this.jobs = tempJobs;
        this.$oruga.notification.open(
          `Moved ${this.draggingRow["Job Name"]} from row ${
            this.draggingRowIndex + 1
          } to ${droppedOnRowIndex + 1}`
        );
      }
    },
  },
};
</script>

<style lang="scss">
.is-selected {
  background: #e4e4e4;
}
.o-table tr > td:nth-of-type(3), tr > th:nth-of-type(3) { background: #ffec99; }
.o-table tr > td:nth-of-type(5), tr > th:nth-of-type(5) { background: #a5d8ff; }
.o-table tr > td:nth-of-type(6), tr > th:nth-of-type(6) { background: #d0bfff; }

@media print {
  // body { font-size: 0.8em; }
  .no-print {
    display: none;
  }
}
</style>
