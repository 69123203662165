import { createApp } from "vue";

import "bulma/bulma.sass";

import Oruga from "@oruga-ui/oruga-next";
import "@oruga-ui/oruga-next/dist/oruga-full-vars.css";

import App from "./App.vue";
import router from "./router";

// icons
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
dom.watch();

createApp(App)
  .use(router)
  .use(Oruga, {
    iconPack: "fas",
  })
  .mount("#app");
