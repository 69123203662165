import { createRouter, createWebHistory } from "vue-router";
import Month from "../views/Month.vue";
import Month2 from "../views/Month2.vue";
import Picker from "../views/Picker.vue";
import SalesPicker from "../views/SalesPicker.vue";
import Dash from "../views/Dash.vue";
import Tomorrow from "../views/Tomorrow.vue";
// import HotSheet from "../views/HotSheet.vue";

const routes = [
  {
    path: "/",
    name: "Month",
    component: Month,
  },
  {
    path: "/month2",
    name: "Month2",
    component: Month2,
  },
  {
    path: "/picker",
    name: "Picker",
    component: Picker,
  },
  {
    path: "/sales-picker",
    name: "SalesPicker",
    component: SalesPicker,
  },
  {
    path: "/dash",
    name: "Dash",
    component: Dash,
  },
  {
    path: "/tomorrow",
    name: "Tomorrow",
    component: Tomorrow,
  },
  // {
  //   path: "/hotsheet",
  //   name: "HotSheet",
  //   component: HotSheet,
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: "/prestige-w-dash/",
});

export default router;
