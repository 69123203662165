<template>
  <!-- <div id="nav">
    <router-link to="/">Month</router-link> |
    <router-link to="/picker">Picker</router-link>
  </div> -->
  <router-view />
</template>

<style lang="scss">
</style>
