<template>
  <div class="picker section">
    <div v-show="showDatepicker" class="columns no-print">
      <div class="column">
        <h4 class="is-size-4">Add New: Enter Dispatch Date</h4>
        <o-button variant="danger" @click="resetForm"
          >Refresh Booked Dates</o-button
        >
        <o-datepicker
          v-model="dispatchDate"
          inline
          :events="unselectableDates"
          :indicators="'dots'"
          :min-date="minDate"
        ></o-datepicker>
        <br />
        <o-button @click="getJobs">Add a booking</o-button>
      </div>
      <div class="column">
        <h4 class="is-size-4">Edit Job / Hot Sheet: Choose a Job</h4>
        Find yourself:
        <o-select v-model="currentSales" placeholder="Find Yourself">
          <option value="AH">AH</option>
          <option value="BL">BL</option>
          <option value="DA">DA</option>
          <option value="KJ">KJ</option>
          <option value="KW">KW</option>
          <option value="ML">ML</option>
          <option value="TO">TO</option>
          <option value="SS">SS</option>
          <option value="INTEL">INTEL</option>
          <option value="OA">OA</option>
          <option value="DRVR">DRVR</option>
          <option value="JA">JA</option>
          <option value="OPS">OPS</option>
          <option value="CD">CD</option>
          <option value="TB">TB</option>
        </o-select>
        <o-button @click="getSalesJobs">Get Jobs</o-button>
        <ul>
          <li v-for="(job, index) in salesJobs" v-bind:key="job.id">
            <a @click="openExistingJob(index)">
              {{ job.fields["Job Name"] }} -
              {{ job.fields["Dispatch Date/Time"] }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div v-show="!showDatepicker" class="columns no-print">
      <div class="column">
        <div>
          <p v-if="dispatchFormat !== ''">
            <strong>Dispatch Date: </strong> {{ dispatchFormat }}
            <span class="tag is-danger" v-if="dateBooked">DAY IS BOOKED</span>
          </p>

          <!-- Job Name -->
          <o-field label="Job Name">
            <o-input v-model="booking['Job Name']" />
          </o-field>

          <!-- Location -->
          <o-field label="Location">
            <o-input v-model="booking['Location']" />
          </o-field>

          <!-- Reg # -->
          <o-field label="REG#">
            <o-input v-model="booking['REG#']" />
          </o-field>

          <!-- Weight -->
          <o-field label="Weight">
            <o-input v-model="booking['Weight']" />
          </o-field>

          <!-- Type -->
          <o-field label="Type">
            <o-select v-model="booking['Type']" placeholder="Select Job Type">
              <option value="Local">Local</option>
              <option value="Office">Office</option>
              <option value="Intra">Intra</option>
              <option value="Interstate">Interstate</option>
              <option value="International">International</option>
              <option value="Military">Military</option>
              <option value="DRIVER">DRIVER</option>
              <option value="DA work">DA work</option>
              <option value="OA work">OA work</option>
              <option value="On-Site Move">On-Site Move</option>
              <option value="Pack">Pack</option>
              <option value="Shuttle">Shuttle</option>
            </o-select>
          </o-field>

          <!-- Sales -->
          <o-field label="Sales">
            <o-select
              v-model="booking['Sales']"
              placeholder="Select Sales Person"
            >
              <option value="AH">AH</option>
              <option value="BL">BL</option>
              <option value="DA">DA</option>
              <option value="KJ">KJ</option>
              <option value="KW">KW</option>
              <option value="ML">ML</option>
              <option value="TO">TO</option>
              <option value="SS">SS</option>
              <option value="INTEL">INTEL</option>
              <option value="OA">OA</option>
              <option value="DRVR">DRVR</option>
              <option value="JA">JA</option>
              <option value="OPS">OPS</option>
              <option value="CD">CD</option>
              <option value="TB">TB</option>
            </o-select>
          </o-field>

          <!-- Notes -->
          <o-field label="Notes">
            <o-input type="textarea" v-model="booking['Notes']" />
          </o-field>

          <!-- Submit -->
          <o-button @click="modalActive = true">Submit Booking</o-button> &nbsp;
          <o-button @click="resetForm()" variant="danger"
            >Cancel &amp; Back</o-button
          >

          <!-- Hot Sheet -->
          <hr />
          <div
            v-if="'Hot Sheets' in booking && booking['Hot Sheets'].length === 0"
          >
            <o-button @click="addHotSheet()"
              >No Hot Sheet yet, add one</o-button
            >
          </div>
          <div
            v-if="
              'Hot Sheets' in booking &&
              booking['Hot Sheets'].length === 1 &&
              'fields' in hotsheet
            "
          >
            <h4 class="is-size-4">Edit Hot Sheet</h4>
            <o-button @click="updateHotSheet()">Update Hot Sheet</o-button>
            <strong v-if="showSubmitted">Updated!</strong>
            <o-button @click="printHotSheet()">Print Hot Sheet</o-button>
            <o-button @click="resetForm()" variant="danger"
              >Cancel &amp; Back to Job Picker</o-button
            ><br /><br />
            <o-field label="Sales Rep Cell">
              <o-input
                v-model="hotsheet.fields['Sales Rep Cell']"
                placeholder="(xxx) xxx-xxxx or all numbers"
              />
            </o-field>

            <!-- Shipper -->
            <div class="columns">
              <div class="column">
                <o-field label="whse front floor Shipper">
                  <o-input
                    v-model="hotsheet.fields['whse front floor Shipper']"
                  />
                </o-field>

                <o-field label="Origin">
                  <o-input v-model="hotsheet.fields['Origin']" />
                </o-field>

                <o-field label="Origin Address">
                  <o-input
                    type="textarea"
                    v-model="hotsheet.fields['Origin Address']"
                  />
                </o-field>

                <o-field label="Origin Home Phone">
                  <o-input
                    v-model="hotsheet.fields['Origin Home Phone']"
                    placeholder="(xxx) xxx-xxxx or all numbers"
                  />
                </o-field>

                <o-field label="Origin Cell Phone">
                  <o-input
                    v-model="hotsheet.fields['Origin Cell Phone']"
                    placeholder="(xxx) xxx-xxxx or all numbers"
                  />
                </o-field>

                <o-field label="Origin Work Phone">
                  <o-input
                    v-model="hotsheet.fields['Origin Work Phone']"
                    placeholder="(xxx) xxx-xxxx or all numbers"
                  />
                </o-field>

                <o-field label="Email">
                  <o-input v-model="hotsheet.fields['Email']" />
                </o-field>
              </div>
              <div class="column">
                <o-field label="Destination Shipper">
                  <o-input v-model="hotsheet.fields['Destination Shipper']" />
                </o-field>

                <o-field label="Destination">
                  <o-input v-model="hotsheet.fields['Destination']" />
                </o-field>

                <o-field label="Destination Address">
                  <o-input
                    v-model="hotsheet.fields['Destination Address']"
                    type="textarea"
                  />
                </o-field>

                <o-field label="Destination Home Phone">
                  <o-input
                    v-model="hotsheet.fields['Destination Home Phone']"
                    placeholder="(xxx) xxx-xxxx or all numbers"
                  />
                </o-field>
                <o-field label="Destination Cell Phone">
                  <o-input
                    v-model="hotsheet.fields['Destination Cell Phone']"
                    placeholder="(xxx) xxx-xxxx or all numbers"
                  />
                </o-field>
                <o-field label="Destination Work Phone">
                  <o-input
                    v-model="hotsheet.fields['Destination Work Phone']"
                    placeholder="(xxx) xxx-xxxx or all numbers"
                  />
                </o-field>
              </div>
            </div>

            <hr />

            <div class="columns">
              <div class="column">
                <!-- Misc Info -->
                <o-field label="TT Accessible">
                  <o-input v-model="hotsheet.fields['TT Accessible']" />
                </o-field>

                <o-field label="Shuttle Parking Location">
                  <o-input
                    v-model="hotsheet.fields['Shuttle Parking Location']"
                  />
                </o-field>

                <o-field label="Parking Permits">
                  <o-input v-model="hotsheet.fields['Parking Permits']" />
                </o-field>

                <o-field label="Permit Location">
                  <o-input v-model="hotsheet.fields['Permit Location']" />
                </o-field>

                <o-field label="Loading Dock">
                  <o-input v-model="hotsheet.fields['Loading Dock']" />
                </o-field>

                <o-field label="Dock Size - HT">
                  <o-input v-model="hotsheet.fields['Dock Size - HT']" />
                </o-field>

                <o-field label="Dock Size - Length">
                  <o-input v-model="hotsheet.fields['Dock Size - Length']" />
                </o-field>

                <o-field label="Elevator">
                  <o-input v-model="hotsheet.fields['Elevator']" />
                </o-field>

                <o-field label="Elevator Floor">
                  <o-input v-model="hotsheet.fields['Elevator Floor']" />
                </o-field>

                <o-field label="Long Carry">
                  <o-input v-model="hotsheet.fields['Long Carry']" />
                </o-field>

                <o-field label="Long Carry QTY">
                  <o-input v-model="hotsheet.fields['Long Carry QTY']" />
                </o-field>

                <o-field label="Stairs">
                  <o-input v-model="hotsheet.fields['Stairs']" />
                </o-field>

                <o-field label="Stairs QTY">
                  <o-input v-model="hotsheet.fields['Stairs QTY']" />
                </o-field>

                <o-field label="Bulky">
                  <o-input v-model="hotsheet.fields['Bulky']" type="textarea" />
                </o-field>

                <o-field label="Bulky Items">
                  <o-input
                    v-model="hotsheet.fields['Bulky Items']"
                    type="textarea"
                  />
                </o-field>

                <o-field label="3rd Party">
                  <o-input
                    v-model="hotsheet.fields['3rd Party']"
                    type="textarea"
                  />
                </o-field>

                <o-field label="3rd Party Items">
                  <o-input
                    v-model="hotsheet.fields['3rd Party Items']"
                    type="textarea"
                  />
                </o-field>

                <o-field label="Truck Size">
                  <o-input v-model="hotsheet.fields['Truck Size']" />
                </o-field>

                <o-field label="Estimated Weight">
                  <o-input v-model="hotsheet.fields['Estimated Weight']" />
                </o-field>

                <o-field label="Minimum Valuation">
                  <o-input v-model="hotsheet.fields['Minimum Valuation']" />
                </o-field>

                <o-field label="Minimum Valuation Charge">
                  <o-input
                    v-model="hotsheet.fields['Minimum Valuation Charge']"
                  />
                </o-field>
              </div>
              <div class="column">
                <!-- Quantities -->
                <o-field label="Dishpack Qty">
                  <o-input
                    v-model="hotsheet.fields['Dishpack Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="Carton 1.5 Qty">
                  <o-input
                    v-model="hotsheet.fields['Carton 1.5 Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="Carton 3.0 Qty">
                  <o-input
                    v-model="hotsheet.fields['Carton 3.0 Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="Carton 4.5 Qty">
                  <o-input
                    v-model="hotsheet.fields['Carton 4.5 Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="Carton 6.0 Qty">
                  <o-input
                    v-model="hotsheet.fields['Carton 6.0 Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="Carton 6.5 Qty">
                  <o-input
                    v-model="hotsheet.fields['Carton 6.5 Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="Wardrobe Carton Qty">
                  <o-input
                    v-model="hotsheet.fields['Wardrobe Carton Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="Mattress Carton Qty">
                  <o-input
                    v-model="hotsheet.fields['Mattress Carton Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="Crib Qty">
                  <o-input
                    v-model="hotsheet.fields['Crib Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="Single 3x3 Qty">
                  <o-input
                    v-model="hotsheet.fields['Single 3x3 Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="Double 4x6 Qty">
                  <o-input
                    v-model="hotsheet.fields['Double 4x6 Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="King/Queen Qty">
                  <o-input
                    v-model="hotsheet.fields['King/Queen Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="King Split Box Qty">
                  <o-input
                    v-model="hotsheet.fields['King Split Box Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="Mirror Pack Qty">
                  <o-input
                    v-model="hotsheet.fields['Mirror Pack Qty']"
                    type="number"
                  />
                </o-field>

                <o-field label="TV Box Qty">
                  <o-input
                    v-model="hotsheet.fields['TV Box Qty']"
                    type="number"
                  />
                </o-field>
              </div>
            </div>

            <hr />

            <!-- Notes -->
            <o-field label="Customer Information &amp; Special Concerns">
              <o-input
                v-model="hotsheet.fields['Cust Info Notes']"
                type="textarea"
              />
            </o-field>

            <o-field label="Dispatch">
              <o-input v-model="hotsheet.fields['Dispatch']" type="textarea" />
            </o-field>

            <o-field label="Packers">
              <o-input v-model="hotsheet.fields['Packers']" type="textarea" />
            </o-field>

            <o-field label="Driver">
              <o-input v-model="hotsheet.fields['Driver']" type="textarea" />
            </o-field>

            <o-button @click="updateHotSheet()">Update Hot Sheet</o-button>
            <strong v-if="showSubmitted">Updated!</strong>
            <o-button @click="printHotSheet()">Print Hot Sheet</o-button>
            <o-button @click="resetForm()" variant="danger"
              >Cancel &amp; Back to Job Picker</o-button
            >
          </div>

          <!-- Modal -->
          <o-modal v-model:active.sync="modalActive">
            <div class="content" v-if="!showSubmitted">
              <h4>Review Booking</h4>
              <ul>
                <li>
                  <strong>Dispatch Date</strong>:
                  {{ dispatchFormat }}
                </li>
                <li><strong>Job Name</strong>: {{ booking["Job Name"] }}</li>
                <li><strong>Location</strong>: {{ booking.Location }}</li>
                <li><strong>REG#</strong>: {{ booking["REG#"] }}</li>
                <li><strong>Weight</strong>: {{ booking.Weight }}</li>
                <li><strong>Type</strong>: {{ booking.Type }}</li>
                <li><strong>Sales</strong>: {{ booking.Sales }}</li>
                <li><strong>Notes</strong>: {{ booking.Notes }}</li>
              </ul>
              <o-button @click="modalActive = false" variant="warning"
                >&larr; Back to Form</o-button
              >&nbsp;
              <o-button @click="submitBooking()">Submit Booking</o-button>
            </div>
            <div
              class="content"
              v-if="showSubmitted"
              style="text-align: center"
            >
              <h4>It's booked!</h4>
              <p>Please wait...</p>
            </div>
          </o-modal>
        </div>
      </div>
    </div>

    <!-- Hot Sheet -->
    <div
      class="sales-printout print-only"
      style="font-family: 'Times New Roman', 'Liberation Serif', serif"
      v-if="
        'Hot Sheets' in booking &&
        booking['Hot Sheets'].length === 1 &&
        'fields' in hotsheet
      "
    >
      <div style="text-align: center; margin-top: -30px">
        <img src="../assets/prestige-logos.png" />
      </div>
      <div class="columns" style="margin-bottom: 0">
        <div class="column">
          <span style="font-size: 1.75em">COD - BILLED</span>
        </div>
        <div class="column" style="text-align: center">
          <span
            style="
              font-size: 2em;
              font-weight: bold;
              text-decoration: underline;
            "
            >HOT SHEET</span
          >
        </div>
        <div class="column">
          Move Date: <span class="s-val">{{ dispatchFormat }}</span
          ><br />
          <em>Sales Rep:</em> <span class="s-val">{{ booking.Sales }}</span
          ><br />
          <em>Rep's Cell:</em>
          <span class="s-val">{{ hotsheet.fields["Sales Rep Cell"] }}</span
          ><br />
        </div>
      </div>
      <div style="margin-top: -30px">
        <span>Billing information:</span> -- Registration #
        <span class="s-val">{{ booking["REG#"] }}</span
        ><br />
        Type of Move: <span class="s-val">{{ booking["Type"] }}</span>
      </div>

      <!-- First Table -->
      <table class="bordered">
        <tr>
          <td colspan="2" style="width: calc(50% - 11px)">
            SHIPPER:
            <span class="s-val">{{
              hotsheet.fields["whse front floor Shipper"]
            }}</span>
          </td>
          <td style="width: 20px"></td>
          <td colspan="2" style="width: calc(50% - 11px)">
            SHIPPER:
            <span class="s-val">{{
              hotsheet.fields["Destination Shipper"]
            }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            ORIGIN: <span class="s-val">{{ hotsheet.fields["Origin"] }}</span>
          </td>
          <td>&nbsp;</td>
          <td colspan="2">
            DESTINATION:
            <span class="s-val">{{ hotsheet.fields["Destination"] }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2" rowspan="2">
            ADDRESS:
            <span class="s-val" style="white-space: pre-line">{{
              hotsheet.fields["Origin Address"]
            }}</span>
          </td>
          <td>&nbsp;</td>
          <td colspan="2" rowspan="2">
            ADDRESS:
            <span class="s-val" style="white-space: pre-line">{{
              hotsheet.fields["Destination Address"]
            }}</span>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td colspan="2">
            HOME PHONE:
            <span class="s-val">{{
              hotsheet.fields["Origin Home Phone"]
            }}</span>
          </td>
          <td>&nbsp;</td>
          <td colspan="2">
            HOME PHONE:
            <span class="s-val">{{
              hotsheet.fields["Destination Home Phone"]
            }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            CELL PHONE:
            <span class="s-val">{{
              hotsheet.fields["Origin Cell Phone"]
            }}</span>
          </td>
          <td>&nbsp;</td>
          <td colspan="2">
            CELL PHONE:
            <span class="s-val">{{
              hotsheet.fields["Destination Cell Phone"]
            }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            WORK PHONE:
            <span class="s-val">{{
              hotsheet.fields["Origin Work Phone"]
            }}</span>
          </td>
          <td>&nbsp;</td>
          <td colspan="2">
            WORK PHONE:
            <span class="s-val">{{
              hotsheet.fields["Destination Work Phone"]
            }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            E-MAIL: <span class="s-val">{{ hotsheet.fields["Email"] }}</span>
          </td>
          <td>&nbsp;</td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td>&nbsp;</td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td colspan="2">
            TT Accessible:
            <span class="s-val">{{ hotsheet.fields["TT Accessible"] }}</span>
          </td>
          <td>&nbsp;</td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td colspan="2">
            Shuttle Parking Location:
            <span class="s-val">{{
              hotsheet.fields["Shuttle Parking Location"]
            }}</span>
          </td>
          <td>&nbsp;</td>
          <td style="text-align: center"><strong>ITEMS</strong></td>
          <td style="text-align: center"><strong>QTY</strong></td>
        </tr>
        <tr>
          <td colspan="2">
            Parking Permits:
            <span class="s-val">{{ hotsheet.fields["Parking Permits"] }}</span>
          </td>
          <td>&nbsp;</td>
          <td>DISHPACK</td>
          <td>
            <span class="s-val">{{ hotsheet.fields["Dishpack Qty"] }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            Permit Location:
            <span class="s-val">{{ hotsheet.fields["Permit Location"] }}</span>
          </td>
          <td>&nbsp;</td>
          <td>CARTONS: 1.5 CU FT</td>
          <td>
            <span class="s-val">{{ hotsheet.fields["Carton 1.5 Qty"] }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            Loading Dock:
            <span class="s-val">{{ hotsheet.fields["Loading Dock"] }}</span>
          </td>
          <td>&nbsp;</td>
          <td>CARTONS: 3.0 CU FT</td>
          <td>
            <span class="s-val">{{ hotsheet.fields["Carton 3.0 Qty"] }}</span>
          </td>
        </tr>
        <tr>
          <td>
            Dock Size: HT:
            <span class="s-val">{{ hotsheet.fields["Dock Size - HT"] }}</span>
          </td>
          <td>
            Length:
            <span class="s-val">{{
              hotsheet.fields["Dock Size - Length"]
            }}</span>
          </td>
          <td>&nbsp;</td>
          <td>CARTONS: 4.5 CU FT</td>
          <td>
            <span class="s-val">{{ hotsheet.fields["Carton 4.5 Qty"] }}</span>
          </td>
        </tr>
        <tr>
          <td>
            Elevator:
            <span class="s-val">{{ hotsheet.fields["Elevator"] }}</span>
          </td>
          <td>
            Floor:
            <span class="s-val">{{ hotsheet.fields["Elevator Floor"] }}</span>
          </td>
          <td>&nbsp;</td>
          <td>CARTONS: 6.0 CU FT</td>
          <td>
            <span class="s-val">{{ hotsheet.fields["Carton 6.0 Qty"] }}</span>
          </td>
        </tr>
        <tr>
          <td>
            Long Carry:
            <span class="s-val">{{ hotsheet.fields["Long Carry"] }}</span>
          </td>
          <td>
            QTY:
            <span class="s-val">{{ hotsheet.fields["Long Carry QTY"] }}</span>
          </td>
          <td>&nbsp;</td>
          <td>CARTONS: 6.5 CU FT</td>
          <td>
            <span class="s-val">{{ hotsheet.fields["Carton 6.5 Qty"] }}</span>
          </td>
        </tr>
        <tr>
          <td>
            Stairs: <span class="s-val">{{ hotsheet.fields["Stairs"] }}</span>
          </td>
          <td>
            QTY: <span class="s-val">{{ hotsheet.fields["Stairs QTY"] }}</span>
          </td>
          <td>&nbsp;</td>
          <td>WARDROBE CARTONS</td>
          <td>
            <span class="s-val">{{
              hotsheet.fields["Wardrobe Carton Qty"]
            }}</span>
          </td>
        </tr>
        <tr>
          <td rowspan="2">
            Bulky:
            <span class="s-val" style="white-space: pre-line">{{
              hotsheet.fields["Bulky"]
            }}</span>
          </td>
          <td rowspan="2">
            Items:
            <span class="s-val" style="white-space: pre-line">{{
              hotsheet.fields["Bulky Items"]
            }}</span>
          </td>
          <td>&nbsp;</td>
          <td>MATTRESS CARTONS</td>
          <td>
            <span class="s-val">{{
              hotsheet.fields["Mattress Carton Qty"]
            }}</span>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>CRIB</td>
          <td>
            <span class="s-val">{{ hotsheet.fields["Crib Qty"] }}</span>
          </td>
        </tr>
        <tr>
          <td rowspan="2">
            3rd Party:
            <span style="white-space: pre-line" class="s-val">{{
              hotsheet.fields["3rd Party"]
            }}</span>
          </td>
          <td rowspan="2">
            Items:
            <span style="white-space: pre-line" class="s-val">{{
              hotsheet.fields["3rd Party"]
            }}</span>
          </td>
          <td>&nbsp;</td>
          <td>SINGLE 3X3</td>
          <td>
            <span class="s-val">{{ hotsheet.fields["Single 3x3 Qty"] }}</span>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>DOUBLE 4X6</td>
          <td>
            <span class="s-val">{{ hotsheet.fields["Double 4x6 Qty"] }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            Truck Size:
            <span class="s-val">{{ hotsheet.fields["Truck Size"] }}</span>
          </td>
          <td>&nbsp;</td>
          <td>KING/QUEEN</td>
          <td>
            <span class="s-val">{{ hotsheet.fields["King/Queen Qty"] }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            Estimated Weight:
            <span class="s-val">{{ hotsheet.fields["Estimated Weight"] }}</span>
          </td>
          <td>&nbsp;</td>
          <td>KING SPLIT BOX</td>
          <td>
            <span class="s-val">{{
              hotsheet.fields["King Split Box Qty"]
            }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            Minimum Valuation:
            <span class="s-val">{{
              hotsheet.fields["Minimum Valuation"]
            }}</span>
          </td>
          <td>&nbsp;</td>
          <td>MIRROR PACKS</td>
          <td>
            <span class="s-val">{{ hotsheet.fields["Mirror Pack Qty"] }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            Minimum Valuation Charge:
            <span class="s-val">{{
              hotsheet.fields["Minimum Valuation Charge"]
            }}</span>
          </td>
          <td>&nbsp;</td>
          <td>TV BOX</td>
          <td>
            <span class="s-val">{{ hotsheet.fields["TV Box Qty"] }}</span>
          </td>
        </tr>
      </table>

      <!-- Customer Info -->
      <table class="bordered" style="width: 100%; max-width: 100%">
        <tr>
          <td style="height: 70px">
            CUSTOMER INFORMATION &amp; SPECIAL CONCERNS:
            <span class="s-val" style="white-space: pre-line">{{
              hotsheet.fields["Cust Info Notes"]
            }}</span>
          </td>
        </tr>
        <tr>
          <td style="height: 90px">
            DISPATCH:
            <span class="s-val" style="white-space: pre-line">{{
              hotsheet.fields["Dispatch"]
            }}</span>
          </td>
        </tr>
        <tr>
          <td style="height: 70px">
            PACKERS:
            <span class="s-val" style="white-space: pre-line">{{
              hotsheet.fields["Packers"]
            }}</span>
          </td>
        </tr>
        <tr>
          <td style="height: 70px">
            DRIVER:
            <span class="s-val" style="white-space: pre-line">{{
              hotsheet.fields["Driver"]
            }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns"; // add parseISO
import axios from "axios";

export default {
  name: "SalesPicker",

  data() {
    return {
      dispatchDate: "",
      showDatepicker: true,
      booking: {
        Status: "Confirmed",
      },
      dateBooked: false,
      unselectableDates: [],
      minDate: new Date(),
      modalActive: false,
      showSubmitted: false,
      salesJobs: [],
      currentSales: false,
      hotsheet: {},
    };
  },

  computed: {
    dispatchFormat: function () {
      if (!this.showDatepicker) {
        return format(this.dispatchDate, "yyyy-MM-dd");
      } else {
        return "";
      }
    },
  },

  methods: {
    getJobs() {
      this.showDatepicker = false;
      this.unselectableDates.map((e) => {
        if (
          format(e.date, "yyyy-MM-dd") ===
          format(this.dispatchDate, "yyyy-MM-dd")
        ) {
          this.dateBooked = true;
          this.booking.Status = "Pending";
        }
        return e;
      });
    },

    async submitBooking() {
      if (!("id" in this.booking)) {
        // Add New
        this.booking["Dispatch Date/Time"] = this.dispatchFormat;

        // Send to Node-Red
        await axios.post(
          "https://nodered.proqsolutions.com/prestige/wAirtablePost",
          this.booking
        );

        this.showSubmitted = true;

        setTimeout(() => {
          this.modalActive = false;
          this.showSubmitted = false;
          this.resetForm();
        }, 3000);
      } else {
        // Update
        const myId = this.booking.id;
        delete this.booking.id;
        delete this.booking["[month-day]"];
        delete this.booking.D;
        delete this.booking["Hot Sheets"];
        await axios.post(
          "https://nodered.proqsolutions.com/prestige/wAirtablePatch",
          { id: myId, fields: this.booking }
        );
        this.showSubmitted = true;
        setTimeout(() => {
          this.modalActive = false;
          this.showSubmitted = false;
          this.resetForm();
        }, 3000);
      }
    },

    resetForm() {
      // Take it back to datepicker
      this.showDatepicker = true;
      this.dateBooked = false;
      this.hotsheet = {};
      this.unselectableDates = [];
      this.getBookedDates();
      this.booking = {
        Status: "Confirmed",
      };
    },

    async getBookedDates() {
      const ret = await axios.get(
        "https://nodered.proqsolutions.com/prestige/wAirtableBooked"
      );
      ret.data.records.map((r) => {
        this.unselectableDates.push({
          date: parseISO(r.fields["Dispatch Date/Time"]),
          type: "danger",
        });
        return r;
      });
    },

    async getSalesJobs() {
      const ret = await axios.get(
        "https://nodered.proqsolutions.com/prestige/wAirtableSalesUpcoming",
        {
          params: {
            sales: this.currentSales,
          },
        }
      );

      this.salesJobs = ret.data.records;
    },

    openExistingJob(index) {
      this.showDatepicker = false;
      this.booking = this.salesJobs[index].fields;
      this.booking.id = this.salesJobs[index].id;
      this.booking.Status = "Changes";

      if (!("Hot Sheets" in this.booking)) {
        this.booking["Hot Sheets"] = [];
      }

      if (this.booking["Hot Sheets"].length === 1) {
        this.getHotSheet(this.booking["Hot Sheets"][0]);
      }

      this.dispatchDate = new Date(
        this.booking["Dispatch Date/Time"] + "T00:00:00"
      );
    },

    async getHotSheet(id) {
      const ret = await axios.get(
        "https://nodered.proqsolutions.com/prestige/wAirtableGetHotSheet",
        {
          params: {
            hs: id,
          },
        }
      );

      this.hotsheet = ret.data;
    },

    async addHotSheet() {
      const ret = await axios.post(
        "https://nodered.proqsolutions.com/prestige/wAirtableAddHotSheet",
        {
          fields: {
            Jobs: [this.booking.id],
          },
        }
      );

      // New Hot sheet
      this.booking["Hot Sheets"] = [ret.data.id];
      this.getHotSheet(ret.data.id);
    },

    async updateHotSheet() {
      // Update
      delete this.hotsheet.fields["Jobs"];
      delete this.hotsheet.fields["Name"];
      delete this.hotsheet.fields["Job Name (from Jobs)"];
      delete this.hotsheet.fields["REG# (from Jobs)"];
      delete this.hotsheet.fields["Dispatch Date/Time (from Jobs)"];
      delete this.hotsheet.fields["Type (from Jobs)"];
      delete this.hotsheet.fields["Sales (from Jobs)"];
      await axios.post(
        "https://nodered.proqsolutions.com/prestige/wAirtableHotSheetPatch",
        { id: this.hotsheet.id, fields: this.hotsheet.fields }
      );
      this.showSubmitted = true;
      setTimeout(() => {
        this.showSubmitted = false;
      }, 3000);
    },

    printHotSheet() {
      window.print();
    },
  },

  created() {
    this.getBookedDates();
  },
};
</script>

<style lang="scss">
.print-only {
  display: none;
}

.sales-printout {
  font-size: 12px;
}

.s-val {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

/* Bordered table */
.bordered {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  td {
    border: 1px solid black;
    padding: 0px 2px;
    word-wrap: break-word;
  }
}

@media print {
  .print-only {
    display: block;
  }
  .no-print {
    display: none !important;
  }
}
</style>
