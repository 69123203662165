<template>
  <div class="picker section">
    <h3 class="is-size-3">Pending Jobs</h3>
    <ul>
      <li v-for="j in pendingJobs" :key="j.id">
        <a @click="loadPending(j)"
          >{{ j.fields["Job Name"] }} - {{ j.fields["Dispatch Date/Time"] }} -
          {{ j.fields.Sales }}</a
        >
      </li>
    </ul>
    <br />
    <o-button @click="getPendingJobs()">Refresh pending jobs</o-button><br />
    <br />

    <div class="columns">
      <div class="column">
        <div>
          <!-- Date-time -->
          <h4 class="is-size-4">Dispatch Date</h4>
          <o-datepicker
            v-model="dispatchDate"
            inline
            :events="events"
            :indicators="'dots'"
          ></o-datepicker>
          <br />
          <o-button @click="getJobs()"
            >Get Jobs, Trucks, & Crew for this date &rarr;</o-button
          >
          <br /><br /><br />

          <o-button variant="warning" @click="clearJob">Clear fields</o-button
          ><br />
          <!-- Job Name -->
          <o-field label="Job Name" :message="addingEditing">
            <o-input v-model="pending.fields['Job Name']" />
          </o-field>

          <!-- Location -->
          <o-field label="Location">
            <o-input v-model="pending.fields['Location']" />
          </o-field>

          <!-- Reg # -->
          <o-field label="REG#">
            <o-input v-model="pending.fields['REG#']" />
          </o-field>

          <!-- Truck -->
          <o-field label="Truck">
            <o-inputitems
              v-model="pending.fields['TruckNames']"
              icon="tag"
              placeholder="Click an item to add ➡️"
              aria-close-label="Delete this item"
            >
            </o-inputitems>
          </o-field>

          <!-- Driver -->
          <o-field label="Driver">
            <o-inputitems
              v-model="pending.fields['DriverNames']"
              icon="tag"
              placeholder="Click an item to add ➡️"
              aria-close-label="Delete this item"
            >
            </o-inputitems>
          </o-field>

          <!-- Crew -->
          <o-field label="Crew">
            <o-inputitems
              v-model="pending.fields['CrewNames']"
              icon="tag"
              placeholder="Click an item to add ➡️"
              aria-close-label="Delete this item"
            >
            </o-inputitems>
          </o-field>

          <!-- Type -->
          <o-field label="Type">
            <o-select
              v-model="pending.fields['Type']"
              placeholder="Select Job Type"
            >
              <option value="Local">Local</option>
              <option value="Office">Office</option>
              <option value="Intra">Intra</option>
              <option value="Interstate">Interstate</option>
              <option value="International">International</option>
              <option value="Military">Military</option>
              <option value="DRIVER">DRIVER</option>
              <option value="DA work">DA work</option>
              <option value="OA work">OA work</option>
              <option value="On-Site Move">On-Site Move</option>
              <option value="Pack">Pack</option>
              <option value="Shuttle">Shuttle</option>
            </o-select>
          </o-field>

          <!-- Sales -->
          <o-field label="Sales">
            <o-select
              v-model="pending.fields['Sales']"
              placeholder="Select Sales Person"
            >
              <option value="AH">AH</option>
              <option value="BL">BL</option>
              <option value="DA">DA</option>
              <option value="KJ">KJ</option>
              <option value="KW">KW</option>
              <option value="ML">ML</option>
              <option value="TO">TO</option>
              <option value="SS">SS</option>
              <option value="INTEL">INTEL</option>
              <option value="OA">OA</option>
              <option value="DRVR">DRVR</option>
              <option value="JA">JA</option>
              <option value="OPS">OPS</option>
              <option value="CD">CD</option>
              <option value="TB">TB</option>
            </o-select>
          </o-field>

          <!-- Type -->
          <o-field label="Status">
            <o-select
              v-model="pending.fields['Status']"
              placeholder="Select Job Status"
            >
              <option value="Checked In">Checked In</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Pending">Pending</option>
              <option value="Postponed">Postponed</option>
              <option value="Cancelled">Cancelled</option>
              <option value="FILE COMPLETED">FILE COMPLETED</option>
              <option value="Off/Out">Off/Out</option>
            </o-select>
          </o-field>

          <!-- Notes -->
          <o-field label="Notes">
            <o-input type="textarea" v-model="pending.fields['Notes']" />
          </o-field>

          <!-- Submit -->
          <o-button variant="warning" @click="clearJob">Clear fields</o-button>
          <o-button @click="submitBooking">Submit Booking</o-button>
        </div>
      </div>
      <div class="column content">
        <h4 v-if="!showDatepicker">
          Jobs this date
        </h4>
        <table v-if="!showDatepicker">
          <thead>
            <tr>
              <th>Job</th>
              <th>Trucks</th>
              <th>Drivers</th>
              <th>Crew</th>
            </tr>
          </thead>
          <tr v-for="j in jobs" :key="j.id">
            <td>
              <a @click="loadPending(j)">{{ j.fields["Job Name"] }}</a>
            </td>
            <!-- <td></td>
            <td></td>
            <td></td> -->
            <td>{{ j.fields.TruckNames.join(", ") }}</td>
            <td>{{ j.fields.DriverNames.join(", ") }}</td>
            <td>{{ j.fields.CrewNames.join(", ") }}</td>
          </tr>
        </table>
        <h4 v-if="!showDatepicker">
          Usages
          <span class="tag is-primary">Available</span>&nbsp;
          <span class="tag is-light">In Use</span>
        </h4>
        <div class="columns" v-if="!showDatepicker">
          <div class="column">
            <h5>Trucks</h5>
            <ul>
              <li v-for="t in trucks" :key="t.id">
                <a
                  @click="useAsset(t, 'truck')"
                  class="tag is-primary"
                  v-if="t.fields.usage === false"
                  >{{ t.fields.Name }}</a
                >
                <a
                  @click="useAsset(t, 'truck')"
                  class="tag is-light"
                  v-if="t.fields.usage === true"
                  >{{ t.fields.Name }}</a
                >
              </li>
            </ul>
          </div>
          <div class="column">
            <h5>Drivers</h5>
            <ul>
              <li v-for="t in drivers" :key="t.id">
                <a
                  @click="useAsset(t, 'driver')"
                  class="tag is-primary"
                  v-if="t.fields.usage === false"
                  >{{ t.fields.Name }}</a
                >
                <a
                  @click="useAsset(t, 'driver')"
                  class="tag is-light"
                  v-if="t.fields.usage === true"
                  >{{ t.fields.Name }}</a
                >
              </li>
            </ul>
          </div>
          <div class="column">
            <h5>Crew</h5>
            <ul>
              <li v-for="t in crew" :key="t.id">
                <a
                  @click="useAsset(t, 'crew')"
                  class="tag is-primary"
                  v-if="t.fields.usage === false"
                  >{{ t.fields.Name }}</a
                >
                <a
                  @click="useAsset(t, 'crew')"
                  class="tag is-light"
                  v-if="t.fields.usage === true"
                  >{{ t.fields.Name }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import axios from "axios";

export default {
  name: "Picker",

  data() {
    return {
      dispatchDate: "",
      jobs: [],
      trucks: [],
      drivers: [],
      crew: [],
      pending: { fields: {} },
      dateBooked: false,
      events: [],
      pendingJobs: [],
      showDatepicker: true,
      addingEditing: "Add a new job, or click a job name to load it",
    };
  },

  computed: {
    dispatchFormat: function () {
      if (this.dispatchDate != "") {
        return format(this.dispatchDate, "yyyy-MM-dd");
      } else {
        return "";
      }
    },
  },

  methods: {
    async getJobs() {
      this.showDatepicker = false;
      let ret = await axios.get(
        "https://nodered.proqsolutions.com/prestige/wAirtableDaily?dispatch=" +
          this.dispatchFormat
      );
      this.jobs = ret.data.jobs;
      this.trucks = ret.data.trucks;
      this.drivers = ret.data.drivers;
      this.crew = ret.data.crew;

      // Keep this for manual flexibility
      this.jobs.map((j) => {
        if (j.fields["Job Name"].toLowerCase() === "booked") {
          this.dateBooked = true;
        }

        // Add trucks, drivers, crew s.t. they can be filled into the fields
        j.fields.TruckNames = [];
        if ("Truck" in j.fields) {
          j.fields.TruckNames = j.fields.Truck.map((t) => {
            const item = this.trucks.find((a) => {
              let ret = false;
              if (a.id === t) {
                ret = true;
              }
              return ret;
            });
            return item.fields.Name;
          });
        }
        j.fields.CrewNames = [];
        if ("Crew" in j.fields) {
          j.fields.CrewNames = j.fields.Crew.map((t) => {
            const item = this.crew.find((a) => {
              let ret = false;
              if (a.id === t) {
                ret = true;
              }
              return ret;
            });
            return item.fields.Name;
          });
        }
        j.fields.DriverNames = [];
        if ("Driver" in j.fields) {
          j.fields.DriverNames = j.fields.Driver.map((t) => {
            const item = this.drivers.find((a) => {
              let ret = false;
              if (a.id === t) {
                ret = true;
              }
              return ret;
            });
            return item.fields.Name;
          });
        }
        return j;
      });

      this.setUsages();
    },

    setUsages() {
      this.trucks = this.trucks.map((t) => {
        t.fields.usage = false;
        for (let n = 0; n < this.jobs.length; n++) {
          if (
            "Truck" in this.jobs[n].fields &&
            this.jobs[n].fields.Truck.includes(t.id)
          ) {
            t.fields.usage = true;
          }
        }
        return t;
      });
      this.drivers = this.drivers.map((t) => {
        t.fields.usage = false;
        for (let n = 0; n < this.jobs.length; n++) {
          if (
            "Driver" in this.jobs[n].fields &&
            this.jobs[n].fields.Driver.includes(t.id)
          ) {
            t.fields.usage = true;
          }
        }
        return t;
      });
      this.crew = this.crew.map((t) => {
        t.fields.usage = false;
        for (let n = 0; n < this.jobs.length; n++) {
          if (
            "Crew" in this.jobs[n].fields &&
            this.jobs[n].fields.Crew.includes(t.id)
          ) {
            t.fields.usage = true;
          }
        }
        return t;
      });
    },

    useAsset(asset, type) {
      if (type === "truck") {
        if ("Truck" in this.pending.fields) {
          this.pending.fields.Truck.push(asset.id);
          this.pending.fields.TruckNames = [
            ...this.pending.fields.TruckNames,
            asset.fields.Name,
          ];
        } else {
          this.pending.fields.Truck = [asset.id];
          this.pending.fields.TruckNames = [asset.fields.Name];
        }
      } else if (type === "driver") {
        if ("Driver" in this.pending.fields) {
          this.pending.fields.Driver.push(asset.id);
          this.pending.fields.DriverNames = [
            ...this.pending.fields.DriverNames,
            asset.fields.Name,
          ];
        } else {
          this.pending.fields.Driver = [asset.id];
          this.pending.fields.DriverNames = [asset.fields.Name];
        }
      } else if (type === "crew") {
        if ("Crew" in this.pending.fields) {
          this.pending.fields.Crew.push(asset.id);
          this.pending.fields.CrewNames = [
            ...this.pending.fields.CrewNames,
            asset.fields.Name,
          ];
        } else {
          this.pending.fields.Crew = [asset.id];
          this.pending.fields.CrewNames = [asset.fields.Name];
        }
      }
    },

    async submitBooking() {
      // Start a spinner?
      delete this.pending.fields.TruckNames;
      delete this.pending.fields.DriverNames;
      delete this.pending.fields.CrewNames;
      delete this.pending.fields.D;
      delete this.pending.fields["[month-day]"];
      delete this.pending.createdTime;

      this.pending.fields["Dispatch Date/Time"] = format(
        this.dispatchDate,
        "yyyy-MM-dd"
      );
      this.pending.fields.Status = "Confirmed";

      // Send to Node-Red
      await axios.post(
        "https://nodered.proqsolutions.com/prestige/wAirtablePatch",
        this.pending
      );

      // Take it back to date picker
      this.pending = { fields: {} };
      this.dispatchDate = "";
      this.jobs = [];
      this.drivers = [];
      this.crew = [];
      this.trucks = [];
      this.events = [];
      this.showDatepicker = true;

      this.getPendingJobs();
      this.getBookedDates();
    },

    async getBookedDates() {
      const ret = await axios.get(
        "https://nodered.proqsolutions.com/prestige/wAirtableBooked"
      );
      ret.data.records.map((r) => {
        this.events.push({
          date: parseISO(r.fields["Dispatch Date/Time"]),
          type: "danger",
        });
        return r;
      });
    },

    async getPendingJobs() {
      const ret = await axios.get(
        "https://nodered.proqsolutions.com/prestige/wAirtablePending"
      );
      this.pendingJobs = ret.data.records;
    },

    loadPending(job) {
      this.dispatchDate = parseISO(job.fields["Dispatch Date/Time"]);
      this.pending = job;
      this.addingEditing = `You're editing ${this.pending.fields["Job Name"]}`;
    },

    clearJob() {
      this.pending = { fields: {} };
      this.addingEditing = "Add a new job, or click a job name to load it";
    },
  },

  created() {
    this.getBookedDates();
    this.getPendingJobs();
  },
};
</script>
