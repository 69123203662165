<template>
  <div class="month">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->

    <section class="section columns">
      <div
        class="column"
        v-for="d in dates1"
        :key="d.date"
        :class="{ booked: d.booked, pending: d.pending, changes: d.changes }"
      >
        <strong
          >{{ d.day }} - <span v-if="d.newmonth">{{ d.month }}</span>
          {{ d.date }}</strong
        >
        <div
          v-for="job in bookingsInDate(d.month, d.date)"
          :key="job.id"
          class="panel"
          :class="`sales_${job.fields.Sales} status_${job.fields.Status.replace(
            ' ',
            '-'
          )}`"
        >
          <p class="panel-heading">{{ job.fields["Job Name"] }}</p>
          <div style="padding: 5px">
            {{ job.fields.Location }}<br />
            <strong>T:</strong>
            <span v-for="(t, index) in job.fields.Truck" :key="t.id"
              ><span v-if="index != 0">, </span>{{ t.fields.Name }}</span
            ><br />
            <strong>D:</strong>
            <span v-for="(t, index) in job.fields.Driver" :key="t.id"
              ><span v-if="index != 0">, </span>{{ t.fields.Name }}</span
            ><br />
            <strong>C:</strong>
            <span v-for="(t, index) in job.fields.Crew" :key="t.id"
              ><span v-if="index != 0">, </span>{{ t.fields.Name }}</span
            ><br />
            {{ job.fields["Notes"] }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { format, add, parseISO } from "date-fns"; // add parseISO
import axios from "axios";

export default {
  name: "Month2",

  data() {
    return {
      dates1: [],
      // dates2: dates2,
      jobs: [],
    };
  },

  methods: {
    async getJobs() {
      const self = this;
      self.setDates();

      // Do it now
      let ret = await axios.get(
        "https://nodered.proqsolutions.com/prestige/wAirtableMonthly"
      );
      ret.data.records = ret.data.records.filter((r) => {
        let ret = false;
        if (
          r.fields.Status === "Confirmed" ||
          r.fields.Status === "Pending" ||
          r.fields.Status === "Checked In" ||
          r.fields.Status === "Changes" ||
          r.fields.Status === "Available"
        ) {
          ret = true;
        }
        return ret;
      });
      ret.data.records = ret.data.records.map((r) => {
        const d = parseISO(r.fields["Dispatch Date/Time"]);
        r.fields.compareDate = format(d, "EEEEEE - d - MMM");
        return r;
      });

      self.jobs = ret.data.records;

      // Do it every 2 minutes
      setInterval(async () => {
        self.setDates();
        let ret = await axios.get(
          "https://nodered.proqsolutions.com/prestige/wAirtableMonthly"
        );
        ret.data.records = ret.data.records.filter((r) => {
          let ret = false;
          if (
            r.fields.Status === "Confirmed" ||
            r.fields.Status === "Pending" ||
            r.fields.Status === "Checked In" ||
            r.fields.Status === "Changes" ||
            r.fields.Status === "Available"
          ) {
            ret = true;
          }
          return ret;
        });
        ret.data.records = ret.data.records.map((r) => {
          const d = parseISO(r.fields["Dispatch Date/Time"]);
          r.fields.compareDate = format(d, "EEEEEE - d - MMM");
          return r;
        });

        self.jobs = ret.data.records;
      }, 120000);
    },

    bookingsInDate(month, date) {
      // Find the date in this.dates to mark as booked if needed
      const n = this.dates1.findIndex((d1) => {
        let ret1 = false;
        if (d1.date === date && d1.month === month) {
          ret1 = true;
        }
        return ret1;
      });

      // Exclude some jobs from counting toward booking. Get a booking total:
      let bookingTotal = 0;

      const b = this.jobs.filter((job) => {
        const d = parseISO(job.fields["Dispatch Date/Time"]);
        let ret = true;

        if (format(d, "MMM") === month && format(d, "d") === date) {
          // Remove "pending" jobs and mark date as "pending"
          if (job.fields.Status === "Pending") {
            if (n !== -1) {
              this.dates1[n].pending = true;
            }
            ret = false;
          }
          // Remove "changes" jobs and mark date as "changes"
          if (job.fields.Status === "Changes") {
            if (n !== -1) {
              this.dates1[n].changes = true;
            }
            // ret = false; // ?
          }
          // Find the date in this.dates and mark it as booked
          if (job.fields["Job Name"].toLowerCase() === "booked") {
            if (n !== -1) {
              this.dates1[n].booked = true;
            }
            ret = false;
          }
        } else {
          ret = false;
        }

        if (
          ret &&
          ["Checked In", "Confirmed", "Changes"].includes(job.fields.Status)
        ) {
          bookingTotal += 1;
        }

        return ret;
      });

      // Mark "booked" if 7 bookings per day, or 4 on saturdays
      if (bookingTotal >= 4) {
        const d = parseISO(b[0].fields["Dispatch Date/Time"]); // Get a date object
        if (d.getDay() === 6 || bookingTotal >= 7) {
          // Find the date in this.dates and mark it as booked
          if (n !== -1) {
            this.dates1[n].booked = true;
          }
        }
      }

      return b;
    },

    setDates() {
      const today = new Date();
      this.dates1 = [];
      let curmonth = "";
      for (let n = 0; n < 16; n++) {
        const d = add(today, {
          years: 0,
          months: 0,
          weeks: 0,
          days: n + 16,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        this.dates1[n] = {
          date: format(d, "d"),
          day: format(d, "EEEEEE"),
          month: format(d, "MMM"),
          booked: false,
          pending: false,
        };
        if (format(d, "MMM") !== curmonth) {
          this.dates1[n].newmonth = true;
          curmonth = format(d, "MMM");
        }
      }
    },
  },

  created() {
    this.getJobs();
  },

  // components: {
  //   HelloWorld,
  // },
};
</script>

<style scoped lang="scss">
.section {
  padding: 1rem 1rem;
}
.column {
  text-align: center;
  background: white;
  position: relative;
  &.booked {
    background: lightcoral;
  }
  &.pending {
    // border-top: 25px solid yellow;
    padding-top: 25px;
    &:before {
      content: "Pending Jobs";
      background: yellow;
      display: block;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
  &.changes {
    padding-top: 25px;
    &:before {
      content: "Changes";
      background: #20d9d2;
      display: block;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: bold;
      color: white;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    }
  }
}
.panel {
  font-size: 87.5%;
  text-align: left;
  background: white;
}
.panel-heading {
  font-size: 1em;
  text-align: center;
}
.panel:not(:last-child) {
  margin-bottom: 0.5rem;
}

// STATUSES
.status_Checked-In {
  position: relative;
  overflow: hidden;
  &:after {
    content: "Checked In";
    display: block;
    position: absolute;
    top: 42%;
    left: -50%;
    background: black;
    color: white;
    text-align: center;
    opacity: 0.5;
    height: 20px;
    width: 200%;
    transform: rotate(45deg);
  }
}
.status_Changes {
  border: 2px solid #20d9d2;
  &:before {
    content: "Changes";
    text-transform: uppercase;
    text-align: center;
    background: #20d9d2;
    font-weight: bold;
    color: white;
    display: block;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  }
}
.status_Available {
  .panel-heading {
    background-color: #faa2c1;
    color: #000000;
  }
}

// SALES PEOPLE
.sales_AH {
  .panel-heading {
    background-color: #ffe8cc;
    color: #000000;
  }
}
.sales_JA {
  .panel-heading {
    background-color: #495057;
    color: #ffffff;
  }
}
.sales_BL {
  .panel-heading {
    background-color: #37b24d;
    color: #ffffff;
  }
}
.sales_CD {
  .panel-heading {
    background-color: #eebefa;
    color: #000000;
  }
}
.sales_DA {
  .panel-heading {
    background-color: #868e96;
    color: #ffffff;
  }
}
.sales_DRVR {
  .panel-heading {
    background-color: #f03e3e;
    color: #ffffff;
  }
}
.sales_INTEL {
  .panel-heading {
    background-color: #862e9c;
    color: #ffffff;
  }
}
.sales_KJ {
  .panel-heading {
    background-color: #f76707;
    color: #000000;
  }
}
.sales_KW {
  .panel-heading {
    background-color: #1c7ed6;
    color: #ffffff;
  }
}
.sales_ML {
  .panel-heading {
    background-color: #868e96;
    color: #ffffff;
  }
}
.sales_OA {
  .panel-heading {
    background-color: #fcc419;
    color: #000000;
  }
}
.sales_OPS {
  .panel-heading {
    background-color: #f8f9fa;
    color: #000000;
  }
}
.sales_SS {
  .panel-heading {
    background-color: #e64980;
    color: #ffffff;
  }
}
.sales_TB {
  .panel-heading {
    background-color: #f8f9fa;
    color: #000000;
  }
}
.sales_TO {
  .panel-heading {
    background-color: #e64980;
    color: #ffffff;
  }
}
</style>
